import gql from 'graphql-tag';

export const CreateMentionReminderNotificationMutation = gql`
	mutation CreateMentionReminderNotificationMutation(
		$contentId: ID!
		$mentionData: [MentionData!]!
	) {
		createMentionReminderNotification(input: { contentId: $contentId, mentionData: $mentionData }) {
			contentId
			success
			errors {
				message
			}
			failedAccountIds
		}
	}
`;
