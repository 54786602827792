import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { cssMap } from '@compiled/react';
import { useMutation } from 'react-apollo';
import { addDays, isFuture } from 'date-fns';

import { Box, Flex, Text, Stack, xcss } from '@atlaskit/primitives';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Checkbox } from '@atlaskit/checkbox';
import CloseIcon from '@atlaskit/icon/core/close';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import Lozenge from '@atlaskit/lozenge';
import Button, { IconButton } from '@atlaskit/button/new';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import LegacyButton from '@atlaskit/button';

import { useSessionData } from '@confluence/session-data';
import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { CreateMentionReminderNotificationMutation } from '../../mutations/CreateMentionReminderNotificationMutation.graphql';
import type {
	CreateMentionReminderNotificationMutation as CreateMentionReminderNotificationType,
	CreateMentionReminderNotificationMutationVariables,
	MentionData,
} from '../../mutations/__types__/CreateMentionReminderNotificationMutation.ts';
import type { Account } from '../../hooks';
import { getLastViewedText } from '../utils';

const TIMESTAMP_TTL_IN_DAYS = 7;
const MAX_SELECTED_USERS_LIMIT = 90;

// For the purposes of the experiment, we're temporarily storing to local storage
const saveRemindersToLocalStorage = (contentId, usersToSave): void => {
	const allReminders = getRemindersFromLocalStorage() || {};
	const currentContentIdSavedReminders = allReminders?.[contentId] || [];

	const accountIds = usersToSave.map((item) => item.mentionedUserAccountId);
	// filter out existing ids as we'll update them again
	const existingReminders = currentContentIdSavedReminders.filter(
		(item) => !accountIds.includes(item.userId),
	);

	// generate new current list
	const timestamp = Date.now();
	const newReminders = accountIds.map((account) => ({ userId: account, timestamp }));

	// new list for single content id
	const newContentIdReminders = existingReminders
		? [...existingReminders, ...newReminders]
		: newReminders;

	// set new list for all reminders
	const newRemindersList: RemindersLocalStorageData = {
		...allReminders,
		[contentId]: newContentIdReminders,
	};

	localStorage.setItem(keys.MENTION_REMINDERS_SENT, JSON.stringify(newRemindersList));
};

const getRemindersFromLocalStorage = (): RemindersLocalStorageData => {
	const storedReminders = localStorage.getItem(keys.MENTION_REMINDERS_SENT);
	const parsedReminders = storedReminders ? JSON.parse(storedReminders) : {};

	// mentions that have been sent 7 days ago are considered expired
	for (const contentId in parsedReminders) {
		const activeReminders = parsedReminders[contentId].filter((item) =>
			isFuture(addDays(new Date(item.timestamp), TIMESTAMP_TTL_IN_DAYS)),
		);
		parsedReminders[contentId] = activeReminders;
	}

	localStorage.setItem(keys.MENTION_REMINDERS_SENT, JSON.stringify(parsedReminders));

	return parsedReminders;
};

const i18n = defineMessages({
	modalHeader: {
		id: 'confluence-analytics.mention-reminders.modal.header',
		defaultMessage: 'Remind teammates to view page',
		description:
			'A header on a modal that tells the user they can remind mentioned users to view the page',
	},
	modalDefaultHelper: {
		id: 'confluence-analytics.mention-reminders.modal.default.helper',
		defaultMessage: 'This re-sends notifications to the @mentioned people you select.',
		description: 'Subtext on a modal that explains the purpose of the modal',
	},
	modalSubtext: {
		id: 'confluence-analytics.mention-reminders.modal.subtext',
		defaultMessage: 'Mentioned users',
		description: 'Subtext on a modal that appears right above a list of mentioned users',
	},
	selectAllButton: {
		id: 'confluence-analytics.mention-reminders.select-all.button',
		defaultMessage: 'Select all',
		description: 'A label on a button to select all checkboxes',
	},
	unselectAllButton: {
		id: 'confluence-analytics.mention-reminders.unselect-all.button',
		defaultMessage: 'Unselect all',
		description: 'A label on a button to unselect all checkboxes',
	},
	notViewedLozenge: {
		id: 'confluence-analytics.mention-reminders.not-viewed.lozenge',
		defaultMessage: 'Not viewed',
		description: 'A lozenge in a checkbox row to denote the user has not viewed the page',
	},
	viewedLozenge: {
		id: 'confluence-analytics.mention-reminders.viewed.lozenge',
		defaultMessage: 'Viewed',
		description: 'A lozenge in a checkbox row to denote the user has viewed the page',
	},
	reminderSentLozenge: {
		id: 'confluence-analytics.mention-reminders.reminder-sent.lozenge',
		defaultMessage: 'Reminder sent',
		description: 'A lozenge in a checkbox row to denote the user has already been sent a reminder',
	},
	deactivatedLozenge: {
		id: 'confluence-analytics.mention-reminders.deactivated.lozenge',
		defaultMessage: 'Deactivated',
		description: 'A lozenge in a checkbox row to denote the user is deactivated',
	},
	unlicensedLozenge: {
		id: 'confluence-analytics.mention-reminders.unlicensed.lozenge',
		defaultMessage: 'Unlicensed',
		description: 'A lozenge in a checkbox row to denote the user is unlicensed',
	},
	modalCancelButton: {
		id: 'confluence-analytics.mention-reminders.cancel.button',
		defaultMessage: 'Cancel',
		description: 'A label on a button to close the modal',
	},
	modalSendButton: {
		id: 'confluence-analytics.mention-reminders.send.button',
		defaultMessage: 'Send',
		description: 'A label on a button to send reminders to mentioned users',
	},
	genericErrorMessage: {
		id: 'confluence-analytics.mention-reminders.generic.error.message',
		defaultMessage:
			'{remindersCount, plural, one {Reminder} other {Reminders}} failed to send. Try sending again.',
		description:
			'A generic error message that appears at the bottom of the modal when something goes wrong',
	},
	maxUserLimitErrorMessage: {
		id: 'confluence-analytics.mention-reminders.user-limit.error.message',
		defaultMessage: 'Reminders are limited to {maxLimit} at a time. Remove some to continue.',
		description:
			'An error message that tells the user they cannot select more than 90 checkboxes at a time',
	},
	noUsersSelectedErrorMessage: {
		id: 'confluence-analytics.mention-reminders.no-users-selected.error.message',
		defaultMessage: 'Select people to send a reminder to.',
		description:
			'An accessible error message that tells the user they have not selected anyone to remind',
	},
	allUsersRemindedErrorMessage: {
		id: 'confluence-analytics.mention-reminders.all-users-reminded.error.message',
		defaultMessage: 'All mentioned users have been reminded.',
		description:
			'An accessible error message that tells the user that all mentioned users have been reminded',
	},
});

export interface ModalProps {
	contentId: string;
	setHidden: () => void;
	showSuccessFlag: () => void;
	mentions: Account[];
	level: string; // page where this was called from
	tabName?: string; // tab where this was called from
}

interface RemindersLocalStorageData {
	[contentId: string]: { userId: string; timestamp: number }[];
}

type MentionsListData = Account & {
	viewed: boolean;
	reminderSent: boolean;
	checked: boolean;
	// temporarily deriving this from the name, rather than relying on isDeactivated
	// https://atlassian.slack.com/archives/CFJ9DJ7P0/p1722467182782669
	isDeactivatedStatus: boolean;
	isUnlicensedStatus: boolean;
};

const renderLozenge = (user) => {
	const { reminderSent, viewed, isDeactivatedStatus, isUnlicensedStatus } = user;
	if (reminderSent) {
		return (
			<Lozenge>
				<FormattedMessage {...i18n.reminderSentLozenge} />
			</Lozenge>
		);
	} else if (isDeactivatedStatus) {
		return (
			<Box>
				<Lozenge>
					<FormattedMessage {...i18n.deactivatedLozenge} />
				</Lozenge>
			</Box>
		);
	} else if (isUnlicensedStatus) {
		return (
			<Box>
				<Lozenge>
					<FormattedMessage {...i18n.unlicensedLozenge} />
				</Lozenge>
			</Box>
		);
	} else if (viewed) {
		return (
			<Box>
				<Lozenge appearance="success">
					<FormattedMessage {...i18n.viewedLozenge} />
				</Lozenge>
			</Box>
		);
	} else {
		return (
			<Lozenge appearance="moved">
				<FormattedMessage {...i18n.notViewedLozenge} />
			</Lozenge>
		);
	}
};

export const MentionRemindersModal = ({
	contentId,
	setHidden,
	mentions,
	showSuccessFlag,
	level,
	tabName,
}: ModalProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { userId } = useSessionData();
	const [mentionsList, setMentionsList] = useState<MentionsListData[]>([]);
	// suppress error if the user takes an action like selecting another checkbox
	const [suppressMutationError, setSuppressMutationError] = useState(false);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'mentionsReminderSelection',
				attributes: {
					level,
					type: tabName,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, level, tabName]);

	useEffect(() => {
		const previouslyRemindedUsers = (getRemindersFromLocalStorage() || {})[contentId] || [];

		const eligibleMentions = mentions.map((user) => {
			const currentUser = previouslyRemindedUsers.find(
				(storedUser) => storedUser.userId === user.id,
			);
			const isDeactivatedStatus = Boolean(user.name?.includes('(Deactivated)'));
			const isUnlicensedStatus = Boolean(user.name?.includes('(Unlicensed)'));

			return {
				...user,
				viewed: Boolean(user.lastViewedAt),
				reminderSent: Boolean(currentUser?.timestamp),
				lastRemindedAt: currentUser?.timestamp,
				checked: Boolean(currentUser?.timestamp) || isDeactivatedStatus || isUnlicensedStatus,
				isDeactivatedStatus,
				isUnlicensedStatus,
			};
		});

		const notViewedAccounts = eligibleMentions
			.filter(
				(user) =>
					!user.lastViewedAt &&
					!user.reminderSent &&
					!user.isDeactivatedStatus &&
					!user.isUnlicensedStatus,
			)
			// auto-select the first five accounts users that haven't view
			.map((user, index) => ({
				...user,
				checked: index < 5,
			}));
		const viewedAccounts = eligibleMentions.filter(
			(user) =>
				!!user.lastViewedAt &&
				!user.reminderSent &&
				!user.isDeactivatedStatus &&
				!user.isUnlicensedStatus,
		);
		const remindedAccounts = eligibleMentions.filter(
			(user) => user.reminderSent && !user.isDeactivatedStatus && !user.isUnlicensedStatus,
		);
		const deactivatedAccounts = eligibleMentions.filter(
			(user) => user.isDeactivatedStatus && !user.isUnlicensedStatus && !user.reminderSent,
		);
		const unlicensedAccounts = eligibleMentions.filter(
			(user) => user.isUnlicensedStatus && !user.isDeactivatedStatus && !user.reminderSent,
		);

		// initialize mentions list in correct order
		setMentionsList([
			...notViewedAccounts,
			...viewedAccounts,
			...remindedAccounts,
			...deactivatedAccounts,
			...unlicensedAccounts,
		]);
	}, [mentions, contentId, userId]);

	const [createMentionReminderNotification, { error: mutationError }] = useMutation<
		CreateMentionReminderNotificationType,
		CreateMentionReminderNotificationMutationVariables
	>(CreateMentionReminderNotificationMutation, {
		onCompleted: (data) => {
			const failedIds = data?.createMentionReminderNotification?.failedAccountIds || [];
			const successfulReminders = usersToRemind.filter(
				(user) => !failedIds.includes(user.mentionedUserAccountId),
			);

			saveRemindersToLocalStorage(contentId, successfulReminders);
			setHidden();
			showSuccessFlag();
		},
		onError: (error) => {
			if (isUnauthorizedError(error)) {
				markErrorAsHandled(error);
			}
		},
	});

	const filterUsersToRemind = (users: MentionsListData[]): MentionsListData[] => {
		return users.filter(
			(user) =>
				user.checked &&
				!user.reminderSent &&
				user.id &&
				!user?.isDeactivatedStatus &&
				!user?.isUnlicensedStatus,
		);
	};

	const usersToRemind: MentionData[] = filterUsersToRemind(mentionsList).map((user) => {
		return {
			mentionedUserAccountId: user.id!,
			mentionLocalIds: user.localIds || [],
		};
	});

	// when all users have already been sent reminders, hide the select all button
	const hideMultiSelectButton = mentionsList.every(
		(user) => user.reminderSent || user.isDeactivatedStatus || user.isUnlicensedStatus,
	);
	const isAllUsersReminded = Boolean(mentionsList.length && hideMultiSelectButton);

	const errorMessage = useMemo(() => {
		if (isAllUsersReminded) {
			return (
				<Text color="color.text.subtlest" size="small">
					<FormattedMessage {...i18n.allUsersRemindedErrorMessage} />
				</Text>
			);
		} else if (!usersToRemind.length) {
			return (
				<Text color="color.text.subtlest" size="small">
					<FormattedMessage {...i18n.noUsersSelectedErrorMessage} />
				</Text>
			);
		} else if (usersToRemind.length > MAX_SELECTED_USERS_LIMIT) {
			return (
				<Text color="color.text.danger" size="small">
					<FormattedMessage
						{...i18n.maxUserLimitErrorMessage}
						values={{ maxLimit: MAX_SELECTED_USERS_LIMIT }}
					/>
				</Text>
			);
		} else if (mutationError && !suppressMutationError) {
			return (
				<Text color="color.text.danger" size="small">
					<FormattedMessage
						{...i18n.genericErrorMessage}
						values={{ remindersCount: usersToRemind.length }}
					/>
				</Text>
			);
		}
		return null;
	}, [usersToRemind, isAllUsersReminded, mutationError, suppressMutationError]);

	const isAllUsersSelected = mentionsList
		.filter(
			(user) => !user?.reminderSent && !user?.isDeactivatedStatus && !user?.isUnlicensedStatus,
		)
		.every((user) => user?.checked);

	const getSecondaryText = (user): string => {
		if (user.reminderSent) {
			return `${intl.formatMessage(i18n.reminderSentLozenge)} ${getLastViewedText(new Date(user.lastRemindedAt), intl)?.toLocaleLowerCase()}`;
		} else if (user.viewed) {
			return `${intl.formatMessage(i18n.viewedLozenge)} ${getLastViewedText(new Date(user.lastViewedAt), intl)?.toLocaleLowerCase()}`;
		}
		return '';
	};

	const handleMultiSelect = () => {
		setSuppressMutationError(true);
		setMentionsList(
			mentionsList.map((user) =>
				user.reminderSent || user.isDeactivatedStatus || user.isUnlicensedStatus
					? user
					: { ...user, checked: !isAllUsersSelected },
			),
		);
	};

	const updateUser = (currentUser) => {
		setSuppressMutationError(true);
		setMentionsList(
			mentionsList.map((mentionedUser) =>
				mentionedUser.id === currentUser.id
					? { ...mentionedUser, checked: !mentionedUser.checked }
					: mentionedUser,
			),
		);
	};

	const handleSendReminders = () => {
		setSuppressMutationError(false);
		if (!usersToRemind.length || usersToRemind.length > MAX_SELECTED_USERS_LIMIT) {
			return;
		}

		void createMentionReminderNotification({
			variables: {
				contentId,
				mentionData: usersToRemind,
			},
		});
	};

	const onCloseHandler = () => {
		setHidden();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'mentionsReminderSelectionDismissed',
				source: 'MentionRemindersModal',
			},
		}).fire();
	};

	const onSubmitHandler = () => {
		handleSendReminders();

		const unviewedCount = filterUsersToRemind(mentionsList).filter((user) => !user.viewed).length;
		const viewedCount = filterUsersToRemind(mentionsList).filter((user) => user.viewed).length;
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'mentionsReminderSelectionClicked',
				source: 'MentionRemindersModal',
				attributes: {
					numberOfRemindedUsers: usersToRemind.length,
					viewedCount,
					unviewedCount,
					level,
					type: tabName,
				},
			},
		}).fire();
	};

	return (
		<ModalTransition>
			<Modal
				isBlanketHidden
				shouldCloseOnEscapePress
				shouldCloseOnOverlayClick
				onClose={onCloseHandler}
			>
				<ModalHeader>
					<ModalTitle>
						<FormattedMessage {...i18n.modalHeader} />
					</ModalTitle>
					<IconButton
						onClick={setHidden}
						appearance="subtle"
						icon={(iconProps) => <CloseIcon {...iconProps} LEGACY_size="small" />}
						label={intl.formatMessage(i18n.modalCancelButton)}
					/>
				</ModalHeader>
				<ModalBody>
					<Box paddingBlockEnd="space.200">
						<Text color="color.text.subtle">
							<FormattedMessage {...i18n.modalDefaultHelper} />
						</Text>
					</Box>
					<Flex direction="row" justifyContent="space-between" xcss={spacingStyle}>
						<Flex alignItems="center">
							<Text weight="medium">
								<FormattedMessage {...i18n.modalSubtext} />
							</Text>
						</Flex>
						<LegacyButton appearance="subtle" onClick={handleMultiSelect} spacing="none">
							<Text color="color.text.accent.blue">
								{!hideMultiSelectButton &&
									(isAllUsersSelected ? (
										<FormattedMessage {...i18n.unselectAllButton} />
									) : (
										<FormattedMessage {...i18n.selectAllButton} />
									))}
							</Text>
						</LegacyButton>
					</Flex>
					{Boolean(mentionsList.length) &&
						mentionsList.map((user) => (
							<Flex key={user.id} alignItems="center" justifyContent="space-between">
								<Flex alignItems="center">
									<Checkbox
										xcss={checkboxStyles.root}
										isIndeterminate={
											user.reminderSent || user.isDeactivatedStatus || user.isUnlicensedStatus
										}
										isDisabled={
											user.reminderSent || user.isDeactivatedStatus || user.isUnlicensedStatus
										}
										isChecked={user.checked}
										label={
											<AvatarItem
												avatar={<Avatar src={user.avatarURL ?? ''} name={user.name ?? ''} />}
												primaryText={user.name ?? ''}
												secondaryText={getSecondaryText(user)}
											/>
										}
										onChange={() => updateUser(user)}
									/>
								</Flex>
								{renderLozenge(user)}
							</Flex>
						))}
				</ModalBody>
				<ModalFooter>
					<Stack>
						<Flex justifyContent="end">
							<Box paddingInlineEnd="space.100">
								<Button appearance="subtle" onClick={onCloseHandler}>
									<FormattedMessage {...i18n.modalCancelButton} />
								</Button>
							</Box>
							<Button
								appearance="primary"
								onClick={onSubmitHandler}
								isDisabled={!usersToRemind.length || isAllUsersReminded}
							>
								<FormattedMessage {...i18n.modalSendButton} />
							</Button>
						</Flex>
						{Boolean(errorMessage) && <Box paddingBlockStart="space.200">{errorMessage}</Box>}
					</Stack>
				</ModalFooter>
			</Modal>
		</ModalTransition>
	);
};

const spacingStyle = xcss({ marginBottom: 'space.050' });
const checkboxStyles = cssMap({
	root: { alignItems: 'center' },
});
