import { useEffect, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { useSessionData } from '@confluence/session-data';
import { markErrorAsHandled, isErrorMarkedAsHandled } from '@confluence/graphql';

import type {
	AnalyticsNonViewersQuery as AnalyticsNonViewersQueryData,
	AnalyticsNonViewersQueryVariables,
} from '../queries/__types__/AnalyticsNonViewersQuery';
import { SitePermissionType } from '../queries/__types__/AnalyticsNonViewersQuery';
import { AnalyticsNonViewersQuery } from '../queries/AnalyticsDialogContent.graphql';

import type { KnownAccount } from './types';
import { AccountType } from './types';
import { usePageViews } from './usePageViews';

export type UsePageMentionsOptions = {
	contentId: string;
	mentionedAccountIds: string[] | null;
	mentionedAccountNames: Record<string, string> | null;
	mentionedAccountLocalIdMapping: Record<string, string[]>;
	viewedCountLimit: number;
	notViewedCountLimit: number;
	skip?: boolean;
};

export type UsePageMentionsResult = {
	mentions: KnownAccount[] | null;
	viewedCount: number;
	notViewedCount: number;
	loadMentions: () => void;
	isLoading: boolean;
	mentionedViewersQueryError: ApolloError | undefined;
	mentionedNonViewersQueryError: ApolloError | undefined;
};

const nonViewerPermissionTypeToMentionableAccountType = {
	[SitePermissionType.APP]: AccountType.NORMAL,
	[SitePermissionType.EXTERNAL]: AccountType.GUEST,
	[SitePermissionType.INTERNAL]: AccountType.NORMAL,
	[SitePermissionType.JSD]: AccountType.NORMAL,
} as const;

export const usePageMentions = ({
	contentId,
	mentionedAccountIds,
	mentionedAccountNames,
	mentionedAccountLocalIdMapping,
	viewedCountLimit,
	notViewedCountLimit,
	skip = false,
}: UsePageMentionsOptions): UsePageMentionsResult => {
	const { userId } = useSessionData();

	const mentionedAccountIdsSet = useMemo<Set<string> | null>(() => {
		if (!mentionedAccountIds) {
			return null;
		}
		return new Set(mentionedAccountIds);
	}, [mentionedAccountIds]);

	const {
		isLoading: isLoadingViewers,
		viewers,
		error: mentionedViewersQueryError,
		loadViewers,
	} = usePageViews(contentId, { skip });

	const viewedMentions = useMemo<KnownAccount[] | null>(() => {
		if (!viewers) {
			return null;
		}
		if (!mentionedAccountIdsSet) {
			return null;
		}
		const knownViewers = viewers.filter((account): account is KnownAccount => {
			if (account.accountType === AccountType.ANONYMOUS) {
				return false;
			}
			return mentionedAccountIdsSet.has(account.id);
		});

		knownViewers.sort((viewer1, viewer2) => {
			return viewer1.lastViewedAt! < viewer2.lastViewedAt!
				? 1
				: viewer1.lastViewedAt! > viewer2.lastViewedAt!
					? -1
					: 0;
		});
		return knownViewers;
	}, [mentionedAccountIdsSet, viewers]);

	const [
		loadNonViewers,
		{
			data: notViewedData,
			loading: isNotViewedMentionsLoading,
			error: mentionedNonViewersQueryError,
		},
	] = useLazyQuery<AnalyticsNonViewersQueryData, AnalyticsNonViewersQueryVariables>(
		AnalyticsNonViewersQuery,
	);

	// Permissions errors returned with status code 200
	if (
		(mentionedNonViewersQueryError?.message.includes('PermissionException') ||
			mentionedNonViewersQueryError?.message.includes('403')) &&
		!isErrorMarkedAsHandled(mentionedNonViewersQueryError)
	) {
		markErrorAsHandled(mentionedNonViewersQueryError);
	}

	const notViewedAccountIdsToFetch = useMemo(() => {
		if (!viewedMentions || !mentionedAccountIds) {
			return null;
		}

		const viewedAccountIdsSet = new Set(viewedMentions.map((account) => account.id));

		const notViewedAccountIds = mentionedAccountIds.filter(
			(accountId) => !viewedAccountIdsSet.has(accountId),
		);

		// in case we need to exclude the current user from the list(s) we request 1 more than is needed.
		const notViewedCountToFetchLimit = notViewedCountLimit + 1;
		const notViewedCountToFetch = Math.min(notViewedAccountIds.length, notViewedCountToFetchLimit);
		return notViewedAccountIds.slice(0, notViewedCountToFetch);
	}, [mentionedAccountIds, notViewedCountLimit, viewedMentions]);

	const notViewedMentions = useMemo<KnownAccount[] | null>(() => {
		// If there weren't any non-viewers to fetch, represent them as [] instead of null (null
		// implying there is loading that needs to be done but hasnt happened yet). This is so that
		// consumers of notViewedMentions understand that non-viewer fetching doesn't need to initiate.
		if (notViewedAccountIdsToFetch && notViewedAccountIdsToFetch.length === 0) {
			return [];
		}

		if (!notViewedData) {
			return null;
		}

		const nonViewers = notViewedData?.confluenceUsers?.nodes ?? [];

		return nonViewers.map<KnownAccount>((nonViewer) => ({
			// @ts-ignore The generated types are unions that are difficult to narrow.
			id: nonViewer?.accountId ?? '',
			name: nonViewer?.displayName ?? '',
			avatarURL: nonViewer?.profilePicture?.path ?? '',
			accountType:
				nonViewerPermissionTypeToMentionableAccountType[nonViewer?.permissionType!] ??
				AccountType.NORMAL,
		}));
	}, [notViewedAccountIdsToFetch, notViewedData]);

	useEffect(() => {
		const hasLoadedNotViewedMentions = !!notViewedMentions;
		if (
			!notViewedAccountIdsToFetch ||
			notViewedAccountIdsToFetch.length === 0 ||
			isNotViewedMentionsLoading ||
			hasLoadedNotViewedMentions
		) {
			return;
		}

		loadNonViewers({
			variables: { accountIds: notViewedAccountIdsToFetch },
		});
	}, [isNotViewedMentionsLoading, loadNonViewers, notViewedAccountIdsToFetch, notViewedMentions]);

	const mentions = useMemo<KnownAccount[] | null>(() => {
		if (!viewedMentions || !notViewedMentions || !mentionedAccountIdsSet) {
			return null;
		}

		viewedMentions.forEach((account) => {
			if (account.id) {
				account.localIds = mentionedAccountLocalIdMapping[account.id] ?? [];
			}
		});

		notViewedMentions.forEach((account) => {
			if (account.id) {
				account.localIds = mentionedAccountLocalIdMapping[account.id] ?? [];
			}
		});

		const shouldExcludeCurrentUserFromViewed = viewedMentions.length > viewedCountLimit;
		const filteredViewedMentions = viewedMentions.filter((account) => {
			if (!shouldExcludeCurrentUserFromViewed) {
				return true;
			}
			return account.id !== userId;
		});
		const limitedViewedMentions = filteredViewedMentions.slice(0, viewedCountLimit);

		const knownMentionedAccountIds = new Set<string>([
			...viewedMentions.map((account) => account.id),
			...notViewedMentions.map((account) => account.id),
		]);
		const missingMentionedAccountIds = [...mentionedAccountIdsSet].filter(
			(accountId) => !knownMentionedAccountIds.has(accountId),
		);
		const notViewedMentionsWithMissing: KnownAccount[] = [
			...notViewedMentions,
			...missingMentionedAccountIds.map<KnownAccount>((accountId) => ({
				id: accountId,
				name: mentionedAccountNames?.[accountId] ?? '',
				avatarURL: '',
				accountType: AccountType.NORMAL,
			})),
		];

		const shouldExcludeCurrentUserFromNotViewed =
			notViewedMentionsWithMissing.length > notViewedCountLimit;
		const filteredNotViewedMentions = notViewedMentionsWithMissing.filter((account) => {
			if (!shouldExcludeCurrentUserFromNotViewed) {
				return true;
			}
			return account.id !== userId;
		});
		const limitedNotViewedMentions = filteredNotViewedMentions.slice(0, notViewedCountLimit);

		return [...limitedViewedMentions, ...limitedNotViewedMentions];
	}, [
		mentionedAccountIdsSet,
		mentionedAccountNames,
		mentionedAccountLocalIdMapping,
		notViewedCountLimit,
		notViewedMentions,
		userId,
		viewedCountLimit,
		viewedMentions,
	]);

	const { viewedCount, notViewedCount } = useMemo(() => {
		if (!viewedMentions || !mentionedAccountIds) {
			return { viewedCount: 0, notViewedCount: 0 };
		}
		return {
			viewedCount: viewedMentions.length,
			notViewedCount: mentionedAccountIds.length - viewedMentions.length,
		};
	}, [mentionedAccountIds, viewedMentions]);

	const isLoading = useMemo(() => {
		if (isLoadingViewers) {
			return true;
		}
		const needsToLoadNotViewedMentions = viewedMentions && !notViewedMentions;
		if (needsToLoadNotViewedMentions) {
			return true;
		}
		return isNotViewedMentionsLoading;
	}, [isLoadingViewers, isNotViewedMentionsLoading, notViewedMentions, viewedMentions]);

	return {
		mentions,
		viewedCount,
		notViewedCount,
		loadMentions: loadViewers,
		isLoading,

		mentionedViewersQueryError,
		mentionedNonViewersQueryError,
	};
};
