import { useMemo, useEffect } from 'react';
import type { ApolloError } from 'apollo-client';

import { useSessionData } from '@confluence/session-data';

import { AccountType } from './types';
import type { Account, KnownAccount } from './types';
import { usePageMentionedAccountIds } from './usePageMentionedAccountIds';
import { usePageMentions } from './usePageMentions';
import { usePageViews } from './usePageViews';

const defaultAccountLimit = 5;
export const USER_NAME_DEACTIVATED_LABEL = '(Deactivated)';
export const USER_NAME_UNLICENSED_LABEL = '(Unlicensed)';
// During rollout of the mentions tab we were instructed by Chopper to limit the
// number of mentions we wish to send to our backend because they've seen in the
// past nefarious/malicious tenants that create pages with thousands of mentions.
// This value was based on analysis performed of the number of mentions that
// reasonably tend to occur.
export const mentionedAccountIdsLimit = 400;
const limitMentionedAccountIds = (accountIds: string[]): string[] =>
	accountIds.slice(0, mentionedAccountIdsLimit);

export type UsePageAudienceOptions = {
	viewersCountLimit?: number;
	mentionsViewedCountLimit?: number;
	mentionsNotViewedCountLimit?: number;
	skip?: boolean;
};

export type UsePageAudienceResult = {
	mentionedAccountIdsError: ApolloError | undefined;
	analyticsViewersQueryError: ApolloError | undefined;

	mentionedViewersQueryError: ApolloError | undefined;
	mentionedNonViewersQueryError: ApolloError | undefined;

	mentionedAccountIds: KnownAccount['id'][];
	loadMentionedAccountIds: () => void;
	isLoadingMentionedAccountIds: boolean;

	viewers: Account[];
	loadViewers: () => void;
	isLoadingViewers: boolean;

	mentionsForReminders: Account[];
	mentions: Account[];
	mentionedViewedCount: number;
	mentionedNotViewedCount: number;
	loadMentions: () => void;
	isLoadingMentions: boolean;
};

export const usePageAudience = (
	contentId: string,
	{
		viewersCountLimit = defaultAccountLimit,
		mentionsViewedCountLimit = defaultAccountLimit,
		mentionsNotViewedCountLimit = defaultAccountLimit,
		skip = false,
	}: UsePageAudienceOptions = {},
): UsePageAudienceResult => {
	const { userId } = useSessionData();
	const {
		isLoading: isLoadingViewers,
		viewers,
		error: analyticsViewersQueryError,
		loadViewers,
	} = usePageViews(contentId, { limit: viewersCountLimit, skip });

	const {
		isLoading: isLoadingMentionedAccountIds,
		mentionedAccountIds,
		mentionedAccountNames,
		error: mentionedAccountIdsError,
		loadMentionedAccountIds,
		mentionedAccountLocalIdMapping,
	} = usePageMentionedAccountIds(contentId);

	useEffect(() => {
		if (!skip) {
			loadMentionedAccountIds();
		}
	}, [skip, loadMentionedAccountIds]);

	const limitedMentionedAccountIds = useMemo<string[] | null>(() => {
		if (!mentionedAccountIds) {
			return null;
		}
		return limitMentionedAccountIds(mentionedAccountIds);
	}, [mentionedAccountIds]);

	const {
		mentions,
		viewedCount: mentionedViewedCount,
		notViewedCount: mentionedNotViewedCount,
		loadMentions,
		isLoading: isLoadingMentions,

		mentionedViewersQueryError,
		mentionedNonViewersQueryError,
	} = usePageMentions({
		contentId,
		mentionedAccountIds: limitedMentionedAccountIds,
		mentionedAccountNames,
		viewedCountLimit: mentionsViewedCountLimit,
		notViewedCountLimit: mentionsNotViewedCountLimit,
		mentionedAccountLocalIdMapping,
		skip,
	});

	const normalizedMentionedAccountIds = useMemo(
		() => limitedMentionedAccountIds ?? [],
		[limitedMentionedAccountIds],
	);
	const normalizedViewers = useMemo(() => viewers ?? [], [viewers]);
	const normalizedMentions = useMemo(() => mentions ?? [], [mentions]);
	const mentionsForReminders = useMemo(
		() =>
			normalizedMentions.filter(
				(user) =>
					user.id &&
					user.accountType === AccountType.NORMAL &&
					user.id !== userId &&
					!user.isDeactivated,
			) ?? [],
		[normalizedMentions, userId],
	);

	return {
		mentionedAccountIdsError,
		analyticsViewersQueryError,

		mentionedViewersQueryError,
		mentionedNonViewersQueryError,

		mentionedAccountIds: normalizedMentionedAccountIds,
		loadMentionedAccountIds,
		isLoadingMentionedAccountIds,

		viewers: normalizedViewers,
		loadViewers,
		isLoadingViewers,

		mentions: normalizedMentions,
		mentionsForReminders,
		mentionedViewedCount,
		mentionedNotViewedCount,
		loadMentions,
		isLoadingMentions,
	};
};
