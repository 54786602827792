export enum AccountType {
	NORMAL = 'normal',
	GUEST = 'guest',
	ANONYMOUS = 'anonymous',
}

export type KnownAccount = {
	id: string;
	name: string;
	avatarURL: string;
	accountType: AccountType.GUEST | AccountType.NORMAL;
	isDeactivated?: boolean;
	lastViewedAt?: string;
	localIds?: string[];
};

export type AnonymousAccount = {
	id: null;
	name: null;
	avatarURL: null;
	accountType: AccountType.ANONYMOUS;
	isDeactivated?: false;
	lastViewedAt?: string;
	localIds?: string[];
};

export type Account = KnownAccount | AnonymousAccount;
