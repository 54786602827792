/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsNonViewersQuery
// ====================================================

export interface AnalyticsNonViewersQuery_confluenceUsers_nodes_Anonymous_profilePicture {
  path: string;
}

export interface AnalyticsNonViewersQuery_confluenceUsers_nodes_Anonymous {
  displayName: string | null;
  profilePicture: AnalyticsNonViewersQuery_confluenceUsers_nodes_Anonymous_profilePicture | null;
  permissionType: SitePermissionType | null;
}

export interface AnalyticsNonViewersQuery_confluenceUsers_nodes_KnownUser_profilePicture {
  path: string;
}

export interface AnalyticsNonViewersQuery_confluenceUsers_nodes_KnownUser {
  displayName: string | null;
  profilePicture: AnalyticsNonViewersQuery_confluenceUsers_nodes_KnownUser_profilePicture | null;
  permissionType: SitePermissionType | null;
  accountId: string | null;
}

export type AnalyticsNonViewersQuery_confluenceUsers_nodes = AnalyticsNonViewersQuery_confluenceUsers_nodes_Anonymous | AnalyticsNonViewersQuery_confluenceUsers_nodes_KnownUser;

export interface AnalyticsNonViewersQuery_confluenceUsers {
  nodes: (AnalyticsNonViewersQuery_confluenceUsers_nodes | null)[] | null;
}

export interface AnalyticsNonViewersQuery {
  confluenceUsers: AnalyticsNonViewersQuery_confluenceUsers | null;
}

export interface AnalyticsNonViewersQueryVariables {
  accountIds?: (string | null)[] | null;
}
