import { useCallback, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { useSessionData } from '@confluence/session-data';
import { markErrorAsHandled, isErrorMarkedAsHandled } from '@confluence/graphql';

import type {
	AnalyticsViewersQuery as AnalyticsViewersQueryData,
	AnalyticsViewersQueryVariables,
} from '../queries/__types__/AnalyticsViewersQuery';
import { SitePermissionType } from '../queries/__types__/AnalyticsViewersQuery';
import { AnalyticsViewersQuery } from '../queries/AnalyticsDialogContent.graphql';

import type { Account, AnonymousAccount, KnownAccount } from './types';
import { AccountType } from './types';

export type UsePageViewsOptions = {
	limit?: number;
	skip?: boolean;
};

export type UsePageViewsResult = {
	error: ApolloError | undefined;
	isLoading: boolean;
	viewers: Account[] | null;
	loadViewers: () => void;
};

const viewerPermissionTypeToNonAnonymousAccountType = {
	[SitePermissionType.APP]: AccountType.NORMAL,
	[SitePermissionType.EXTERNAL]: AccountType.GUEST,
	[SitePermissionType.INTERNAL]: AccountType.NORMAL,
	[SitePermissionType.JSD]: AccountType.NORMAL,
} as const;

export const usePageViews = (
	contentId: string,
	{ limit, skip }: UsePageViewsOptions = {},
): UsePageViewsResult => {
	const { userId } = useSessionData();

	const [fetchViewers, { data, loading: isLoading, error }] = useLazyQuery<
		AnalyticsViewersQueryData,
		AnalyticsViewersQueryVariables
	>(AnalyticsViewersQuery);

	// Permissions errors returned with status code 200
	if (
		(error?.message.includes('PermissionException') || error?.message.includes('403')) &&
		!isErrorMarkedAsHandled(error)
	) {
		markErrorAsHandled(error);
	}

	const loadViewers = useCallback(() => {
		if (skip) {
			return;
		}
		const variables: AnalyticsViewersQueryVariables = { contentId };
		if (limit) {
			// in case we need to exclude the current user we request 1 more than is needed.
			variables.limit = limit + 1;
		}
		fetchViewers({ variables });
	}, [contentId, fetchViewers, limit, skip]);

	const fetchedViewers: Account[] | null = useMemo(() => {
		if (!data) {
			return null;
		}

		const pageViews = data?.singleContent?.contentAnalyticsViewsByUser?.pageViews ?? [];

		return pageViews.map<Account>((pageView) => {
			const isAnonymous = !pageView.userProfile;

			if (isAnonymous) {
				const anonymousAccount: AnonymousAccount = {
					id: null,
					name: null,
					avatarURL: null,
					accountType: AccountType.ANONYMOUS,
					lastViewedAt: pageView.lastViewedAt,
				};
				return anonymousAccount;
			}
			const knownAccount: KnownAccount = {
				id: pageView.userProfile?.id!,
				name: pageView.userProfile?.displayName ?? '',
				avatarURL: pageView.userProfile?.photos?.[0]?.value ?? '',
				lastViewedAt: pageView.lastViewedAt,
				accountType:
					viewerPermissionTypeToNonAnonymousAccountType[
						pageView.userProfile?.confluence?.permissionType!
					] ?? AccountType.NORMAL,
				isDeactivated: !pageView.userProfile?.isActive,
			};
			return knownAccount;
		});
	}, [data]);

	const viewers: Account[] | null = useMemo(() => {
		if (!fetchedViewers) {
			return null;
		}
		const shouldExcludeCurrentUser = limit && fetchedViewers.length > limit;

		const filteredViewers = fetchedViewers.filter((account) => {
			if (account.id === userId && shouldExcludeCurrentUser) {
				return false;
			}
			return true;
		});

		if (limit) {
			return filteredViewers.slice(0, limit);
		}
		return filteredViewers;
	}, [fetchedViewers, limit, userId]);

	return {
		error,
		isLoading,
		viewers,
		loadViewers,
	};
};
